import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { MdBarChart, MdViewWeek } from "react-icons/md";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import tableDataCheck from "./variables/tableDataCheck";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { BsCart2 } from "react-icons/bs";
import { FiShoppingBag } from "react-icons/fi";
import React, { useState } from "react";
import CustomDialog from "components/dialog";
import NumberFormatCustom from "utils/formatters/number_formatter";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "components/fields/InputField";
import axiosInstance from "services/axios";
import APIService from "services/api";
import { useAppSelector } from "hooks/apphook";
import { RootState } from "store";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

interface DialogProp {
  setOpen: any;
}

const Dashboard = () => {
  const [openWallet, setOpenWallet] = React.useState(false);

  return (
    <div>
      <CustomDialog
        open={openWallet}
        setOpen={setOpenWallet}
        maxWidth="lg"
        title="Fund Merchant Wallet"
        content={<InitFundWallet setOpen={setOpenWallet} />}
      />
      {/* Card widget */}
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex-grow-1 mt-3 grid grid-cols-1 gap-5 md:col-span-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Transactions"}
            subtitle={"$340.5"}
            button1={
              <Button
                sx={{
                  textTransform: "capitalize",
                  height: 21,
                  textAlign: "end",
                  fontSize: 13,
                  fontStyle: "italic",
                }}
                onClick={() => setOpenWallet(true)}
              >
                Topup wallet
              </Button>
            }
            button2={
              <Button
                sx={{
                  textTransform: "capitalize",
                  height: 21,
                  textAlign: "end",
                  fontSize: 13,
                  fontStyle: "italic",
                }}
                onClick={() => alert("Hello kdjss")}
              >
                View balance
              </Button>
            }
          />
          <Widget
            icon={<MdViewWeek className="h-6 w-6" />}
            title={"Week's Transactions"}
            subtitle={"$1,000"}
            button1={
              <div className="mb-6 flex items-center justify-center">
                <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                  <option value="weekly">Weekly</option>
                </select>
              </div>
            }
          />
          <Widget
            icon={<BsCart2 className="h-7 w-7" />}
            title={"Utility Orders"}
            subtitle={"145"}
            button1={
              <div className="mb-6 flex items-center justify-center">
                <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                  <option value="weekly">Weekly</option>
                </select>
              </div>
            }
          />
          <Widget
            icon={<FiShoppingBag className="h-6 w-6" />}
            title={"Voucher Orders"}
            subtitle={"$2433"}
            button1={
              <div className="mb-6 flex items-center justify-center">
                <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                  <option value="weekly">Weekly</option>
                </select>
              </div>
            }
          />
        </div>
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div>

      {/* Tables & Charts */}
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={7}>
          <CheckTable tableData={tableDataCheck} />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <PieChartCard />
        </Grid>
      </Grid>
    </div>
  );
};

const InitFundWallet = ({ setOpen }: DialogProp) => {
  const { merchantId, profile } = useAppSelector(
    (state: RootState) => state.auth
  );
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required("Amount is required"),
  });

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        // Initiate fund request here
        const payload = {
          amount: values.amount,
          merchant_id: merchantId,
          email: profile?.email,
          phone: profile?.phone ?? "08093869330",
          currency: "NGN",
        };
        console.log("PAYLOAD :: ", payload);

        setTimeout(() => {
          setLoading(false);
          setOpen(false);
          //Now show dialog here
          handleFlutterPayment({
            callback: (response) => {
              console.log(response);
              closePaymentModal(); // this will close the modal programmatically
            },
            onClose: () => {
              closePaymentModal();
            },
          });
        }, 3000);

        // const response = await APIService.initiateFundWallet(payload);
        // console.log("INIT FUND WALLET RESPONSE ::: ", response.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  const { touched, errors, handleSubmit, getFieldProps, values } = formik;

  const config = {
    public_key: "FLWPUBK_TEST-0f8c4dee06ea412707f09c879101f7a1-X",
    tx_ref: Date.now().toString(),
    amount: values.amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: "user@gmail.com",
      phone_number: "070********",
      name: "john doe",
    },
    customizations: {
      title: "Merchant Fund Wallet",
      description: "Topup your wallet to serve your customers better",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <Box
      p={3}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <Box width={"100%"}>
        <Typography gutterBottom>
          Enter amount to fund in the input field below to proceed
        </Typography>
        <br />
        <TextField
          sx={{ width: "100%", borderRadius: 16 }}
          variant="outlined"
          placeholder="₦20,000"
          {...getFieldProps("amount")}
          size="small"
          // component={InputField}

          value={formik.values.amount}
          error={Boolean(touched.amount && errors.amount)}
          helperText={touched.amount && errors.amount}
          InputProps={{
            inputComponent: NumberFormatCustom,
            fullWidth: true,
          }}
        />
        <Box m={4} />
        <Button
          disabled={loading}
          variant="contained"
          fullWidth
          onClick={() => handleSubmit()}
          sx={{ textTransform: "capitalize" }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
