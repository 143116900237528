import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface RequestState {
  requests: any;
}

const initialState: RequestState = {
  requests: null,
};

export const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setRequests: (state, action: PayloadAction<any>) => {
      state.requests = action.payload;
    },
  },
});

export const { setRequests } = requestSlice.actions;

export default requestSlice.reducer;
