import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  NativeSelect,
  Toolbar,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import "react-phone-number-input/style.css";
import InputField from "components/fields/InputField";
import * as Yup from "yup";
import SelectField from "components/fields/SelectField";
import { RootState } from "store";
import { useAppSelector } from "hooks/apphook";


const industries = [
  {
    label: "Transport & Logistics",
    value: "transport & logistics",
  },
  {
    label: "Food & Hospitality",
    value: "food & hospitality",
  },
  {
    label: "E-commerce",
    value: "e-commerce",
  },
  {
    label: "Government Agency",
    value: "government agency",
  },
  {
    label: "NGOs & Non-Profit",
    value: "ngo & non-profit",
  },
  {
    label: "Digital Agency",
    value: "digital agency",
  },
];

const BusinessProfile = () => {
  const { profile } = useAppSelector((state: RootState) => state.settings);

  const validationSchema = Yup.object().shape({
    business_name: Yup.string().required("Business name is required"),
    business_email: Yup.string()
      .email("Business email is not valid")
      .required("Email address is required"),
    business_phone: Yup.string().required("Business phone number is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State/region is required"),
    city: Yup.string().required("City is required"),
    zip_code: Yup.string().required("Zip code is required"),
    street: Yup.string().required("Office address is required"),
    reg_num: Yup.string().required("Business reg number is required"),
    industry: Yup.string().required("Business industry is required"),
    website: Yup.string().nullable(),
    language: Yup.string().nullable(),
  });

  const initialValues = {
    business_name: "",
    business_email: "",
    business_phone: "",
    street: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    reg_num: "",
    industry: "",
    website: "",
    language: "en",
  };

  // const submit = (value: any) => {
  //   console.log("SUBMIT TRIGGERED HERE :::: ");
  // };

  return (
    <div className="flex w-[100%] flex-row text-navy-700 dark:text-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(v) => {
          console.log("EMAIL  ::: ", v);
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          values,
          isValid,
          getFieldProps,
        }) => (
          <Box
            component={Form}
            p={2}
            py={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box pb={5}>
              <Avatar sx={{ width: 100, height: 100 }} />
            </Box>

            <Grid container spacing={2} my={0.25}>
              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="business_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Merchant name*
                  </label>
                  <Field
                    type="text"
                    id="business_name"
                    name="business_name"
                    component={InputField}
                    {...getFieldProps("business_name")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.business_name && errors.business_name
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter business name"
                  />
                  <ErrorMessage
                    name="business_name"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="business_email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Business email*
                  </label>
                  <Field
                    type="email"
                    id="business_email"
                    name="business_email"
                    component={InputField}
                    {...getFieldProps("business_email")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.business_email && errors.business_email
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter business email"
                  />
                  <ErrorMessage
                    name="business_email"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Business phone*
                  </label>
                  <Field
                    type="number"
                    id="business_phone"
                    name="business_phone"
                    component={InputField}
                    {...getFieldProps("business_phone")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.business_phone && errors.business_phone
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter business phone"
                  />
                  <ErrorMessage
                    name="business_phone"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} my={0.25}>
              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="reg_num"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Reg number*
                  </label>
                  <Field
                    type="number"
                    id="reg_num"
                    name="reg_num"
                    component={InputField}
                    {...getFieldProps("reg_num")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.reg_num && errors.reg_num
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter registration number"
                  />
                  <ErrorMessage
                    name="reg_num"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="industry"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Industry*
                  </label>
                  <Field
                    type="number"
                    id="industry"
                    name="industry"
                    items={industries}
                    component={SelectField}
                    {...getFieldProps("industry")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.industry && errors.industry
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Select industry"
                  />
                  <ErrorMessage
                    name="industry"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country*
                  </label>
                  <Field
                    type="text"
                    id="country"
                    name="country"
                    items={industries}
                    component={SelectField}
                    {...getFieldProps("country")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.country && errors.country
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Select country"
                  />
                  <ErrorMessage
                    name="country"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} my={0.25}>
              

              <Grid item xs={12} sm={6} md={4} >
                <div>
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State*
                  </label>
                  <Field
                    type="test"
                    id="state"
                    name="state"
                    component={InputField}
                    {...getFieldProps("state")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.state && errors.state
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter state/region"
                  />
                  <ErrorMessage
                    name="state"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City*
                  </label>
                  <Field
                    type="test"
                    id="city"
                    name="city"
                    component={InputField}
                    {...getFieldProps("city")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.city && errors.city
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter city"
                  />
                  <ErrorMessage
                    name="city"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="zip_code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Zip code*
                  </label>
                  <Field
                    type="test"
                    id="zip_code"
                    name="zip_code"
                    component={InputField}
                    {...getFieldProps("zip_code")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.zip_code && errors.zip_code
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter zip code"
                  />
                  <ErrorMessage
                    name="zip_code"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
            </Grid>
            
            <Grid container spacing={2} my={0.25}>
              <Grid item xs={12} sm={6}>
                <div>
                  <label
                    htmlFor="street"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Street Address*
                  </label>
                  <Field
                    type="test"
                    id="street"
                    name="street"
                    component={InputField}
                    {...getFieldProps("street")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.street && errors.street
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter street address"
                  />
                  <ErrorMessage
                    name="street"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Website
                  </label>
                  <Field
                    type="test"
                    id="website"
                    name="website"
                    component={InputField}
                    {...getFieldProps("website")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.website && errors.website
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter website (optional)"
                  />
                  <ErrorMessage
                    name="website"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
            </Grid>
            <br />

            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{ textTransform: "capitalize", borderRadius: 12 }}
            >
              Continue
            </Button>
          </Box>
        )}
      </Formik>
    </div>
  );
};

export default BusinessProfile;
