import React from "react";
import Preference from "./pages/business/preference";
import ActivityLog from "./pages/activity/logs";
import Profile from "./pages/business/profile";
import { Avatar, Box, Button, Grid, TextField, Typography } from "@mui/material";
import {
  MdArrowForward,
  MdBusiness,
  MdGroup,
  MdPerson3,
  MdSecurity,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMdGift } from "react-icons/io";
import { FaServicestack } from "react-icons/fa";
import CustomDialog from "components/dialog";
import Team from "./pages/business/businessprofile";
import SecurityTab from "./pages/security/security";
import ResetPassword from "./pages/security/reset_pass";
import { useAppSelector } from "hooks/apphook";
import { RootState } from "store";
import AddTeamMember from "./pages/team/add";
import OtpInput from "react-otp-input";

interface ItemI {
  title: string;
  component: any;
}

interface ConfirmProps {
  setOpen: any;
}

const Settings = () => {
  // const [currIndex, setCurrIndex] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [title, setTitle] = React.useState<string | null>(null);
  const [currentContent, setCurrentContent] = React.useState(<div />);
  const [resetPassOTP, setResetPassOTP] = React.useState("");

  const { profile } = useAppSelector((state: RootState) => state.auth);

  // const changeTab = (index: number) => {
  //   setCurrIndex(index);
  // };

  // const tabs: ItemI[] = [
  //   {
  //     title: "Preference",
  //     component: <Preference />,
  //   },
  //   {
  //     title: "Profile",
  //     component: <Profile />,
  //   },
  //   {
  //     title: "Logs",
  //     component: <ActivityLog />,
  //   },
  // ];

  return (
    <div className="flex flex-col items-start justify-start p-2 ">
      <CustomDialog
        open={open}
        setOpen={setOpen}
        content={currentContent}
        title={title}
      />
      <CustomDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        content={currentContent}
        title={title}
      />
      <div className="flex flex-col items-start justify-start">
        <p className="shrink text-[15px] capitalize text-navy-700 dark:text-white">
          Manage your settings and preferences
        </p>
      </div>
      <br />
      <div className="flex  w-[100%] flex-col bg-white p-5 pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white">
        <p className=" shrink text-[18px] capitalize text-navy-700 dark:text-white">
          Account Settings
        </p>
        <br />
        <div className="h-[1px] w-[100%] bg-[grey] text-navy-700 dark:text-white" />
        <br />
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection={"row"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                <Avatar>
                  <MdBusiness />
                </Avatar>
                <Box
                  px={4}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <p className="text-[18px] font-[600] capitalize text-navy-700 dark:text-white">
                    Business
                  </p>
                  <p className="shrink text-[13px] capitalize italic text-[gray] dark:text-white">
                    Setup your business
                  </p>
                  <div
                    onClick={() => {
                      setTitle("Profile Information");
                      setCurrentContent(<Profile />);
                      setOpen(true);
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                    >
                      <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                        Profile{" "}
                      </p>
                      <MdArrowForward size={13} />
                    </Box>
                  </div>
                  {profile?.claim === "owner" && (
                    <div
                      onClick={() => {
                        setTitle("Business Information");
                        setCurrentContent(<Team />);
                        setOpen(true);
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection={"row"}
                        justifyContent={"start"}
                        alignItems={"center"}
                      >
                        <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                          Business profile{" "}
                        </p>
                        <MdArrowForward size={13} />
                      </Box>
                    </div>
                  )}

                  <div
                    onClick={() => {
                      setTitle("Account Preference");
                      setCurrentContent(<Preference />);
                      setOpen(true);
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                    >
                      <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                        Preferences{" "}
                      </p>
                      <MdArrowForward size={13} />
                    </Box>
                  </div>
                </Box>
              </Box>
            </Grid>

            {/* SECURITY HERE */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection={"row"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                <Avatar>
                  <MdSecurity />
                </Avatar>
                <Box
                  px={4}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <p className="text-[18px] font-[600] capitalize text-navy-700 dark:text-white">
                    Security
                  </p>
                  <p className="shrink text-[13px] capitalize italic text-[gray] dark:text-white">
                    Secure your account
                  </p>

                  <div
                    onClick={() => {
                      setTitle("Confirm Password Reset");
                      setCurrentContent(
                        <SetPasswordAlert setOpen={setOpenConfirm} />
                      );
                      setOpenConfirm(true);
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                    >
                      <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                        Reset Password{" "}
                      </p>
                      <MdArrowForward size={13} />
                    </Box>
                  </div>

                  {/* <div
                    onClick={() => {
                      setTitle("2FA Authentication");
                      setCurrentContent(<ResetPassword />);
                      setOpen(true);
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                    >
                      <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                        2FA Authentication
                      </p>
                      <MdArrowForward size={13} />
                    </Box>
                  </div> */}
                </Box>
              </Box>
            </Grid>

            {/* TEAM MEMBERS HERE */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                display="flex"
                flexDirection={"row"}
                justifyContent={"start"}
                alignItems={"start"}
              >
                <Avatar>
                  <MdGroup />
                </Avatar>
                <Box
                  px={4}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <p className="text-[18px] font-[600] capitalize text-navy-700 dark:text-white">
                    Team
                  </p>
                  <p className="shrink text-[13px] capitalize italic text-[gray] dark:text-white">
                    Manage your in-house team
                  </p>

                  <div
                    onClick={() => {
                      setTitle("Add New Member");
                      setCurrentContent(<AddTeamMember />);
                      setOpen(true);
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                    >
                      <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                        Add Member
                      </p>
                      <MdArrowForward size={13} />
                    </Box>
                  </div>

                  <div
                    onClick={() => {
                      setTitle("2FA Authentication");
                      setCurrentContent(<ResetPassword />);
                      setOpen(true);
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection={"row"}
                      justifyContent={"start"}
                      alignItems={"center"}
                    >
                      <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                        Manage members
                      </p>
                      <MdArrowForward size={13} />
                    </Box>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <br />
      {profile?.claim === "owner" && (
        <div className="flex  w-[100%] flex-col bg-white p-5 pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white">
          <p className=" shrink text-[18px] capitalize text-navy-700 dark:text-white">
            Product Settings
          </p>
          <br />
          <div className="h-[1px] w-[100%] bg-[grey] text-navy-700 dark:text-white" />
          <br />
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Avatar>
                    <IoMdGift />
                  </Avatar>
                  <Box
                    px={4}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <p className="text-[18px] font-[600] capitalize text-navy-700 dark:text-white">
                      Voucher
                    </p>
                    <p className="shrink text-[13px] capitalize italic text-[gray] dark:text-white">
                      Set voucher preferences
                    </p>

                    <Link to={"/"}>
                      <Box
                        display="flex"
                        flexDirection={"row"}
                        justifyContent={"start"}
                        alignItems={"center"}
                      >
                        <p className="shrink py-1 pr-1 text-[14px] capitalize text-navy-700 dark:text-white">
                          Preferences{" "}
                        </p>
                        <MdArrowForward size={13} />
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  justifyContent={"start"}
                  alignItems={"start"}
                >
                  <Avatar>
                    <FaServicestack />
                  </Avatar>
                  <Box
                    px={4}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"start"}
                    alignItems={"start"}
                  >
                    <p className="text-[18px] font-[600] capitalize text-navy-700 dark:text-white">
                      Utility
                    </p>
                    <p className="shrink text-[13px] capitalize italic text-[gray] dark:text-white">
                      Set utility preferences
                    </p>
                    <Link to={"/"}>
                      <Box
                        display="flex"
                        flexDirection={"row"}
                        justifyContent={"start"}
                        alignItems={"center"}
                      >
                        <p className="text-[14px]capitalize shrink py-1 pr-1 text-navy-700 dark:text-white">
                          {"Preferences"}
                        </p>
                        <MdArrowForward size={13} />
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

const SetPasswordAlert = ({ setOpen }: ConfirmProps) => {
  const [openOTP, setOpenOTP] = React.useState(false);

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <CustomDialog
        open={openOTP}
        setOpen={setOpenOTP}
        title="Enter OTP code"
        content={<EnterOTPAlert setOpen={setOpenOTP} />}
      />
      <Typography gutterBottom>
        Are you sure you want to reset your password?
      </Typography>
      <Box
        mt={2}
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 10,
            fontSize: 13,
            mx: 1,
            textTransform: "capitalize",
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          sx={{
            borderRadius: 10,
            fontSize: 13,
            textTransform: "capitalize",
          }}
          onClick={() => {
            setOpen(false);
            setOpenOTP(true);
          }}
        >
          Proceed
        </Button>
      </Box>
    </Box>
  );
};

const EnterOTPAlert = ({ setOpen }: ConfirmProps) => {
  const [openFinal, setOpenFinal] = React.useState(false);
  const [otp, setOtp] = React.useState("");

  return (
    <Box
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      alignItems={"start"}
    >
      <CustomDialog
        open={openFinal}
        setOpen={setOpenFinal}
        title="New password dialog"
        content={<p>Enter the new password here</p>}
      />
      <Typography gutterBottom>
        Enter the OTP code sent to your email to continue
      </Typography>
      <Box width={256} >
        {/* <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderSeparator={<span className="mx-2" > </span>}
          renderInput={(props) => <TextField {...props} />}
        /> */}
         <OtpInput
          value={otp}
          onChange={setOtp}
          // onChange={(value) => {
          //   // let otpValue = '';
          //   // otpValue += value;
          //   // setFieldValue('otp', value);
          //   // if (otpValue.length === OTP_LENGTH) {
          //   //   return handleSubmit();
          //   // }
          // }}
          inputType="number"
          shouldAutoFocus
          numInputs={4}
          inputStyle={{
            width: '100%',
            height: 50,
            marginRight: 10,
            marginLeft: 10,
            borderRadius: 8,
            fontSize: 18,
          }}
          renderSeparator={<span></span>}
          renderInput={(props) => (
            <input {...props} />
          )}
        />
      </Box>
      
      <Box
        mt={2}
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <p>Resend code in </p>
        {/* <Button
          variant="contained"
          sx={{
            borderRadius: 10,
            fontSize: 13,
            textTransform: "capitalize",
          }}
          onClick={() => {
            // setOpen(false);
            // setOpenOTP(true);
          }}
        >
          Proceed
        </Button> */}
      </Box>
    </Box>
  );
};

export default Settings;
