import Banner from "./components/Banner";
import NFt2 from "assets/img/nfts/Nft2.png";
import NFt3 from "assets/img/nfts/Nft3.png";

import tableDataTopCreators from "views/admin/getstarted/variables/tableDataTopCreators";
import NftCard from "components/card/NftCard";
import SupportedCountriesTable from "./components/SupportedCountries";

const Getstarted = () => {
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        {/* NFt Banner */}
        <Banner />
        <br />

        {/* NFt Header */}
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Services
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <p
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              >
                Buy 
              </p>
            </li>
            <li>
              <p
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              >
                Redeem
              </p>
            </li>
            <li>
              <p
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              >
                Collect
              </p>
            </li>
            <li>
              <p
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              >
                Utility
              </p>
            </li>
          </ul>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-2">
          <NftCard
            title="Voucher"
            author="Buy. Trade. Redeem Vouchers with ease"
            image={NFt3}
          />
          <NftCard
            title="Utility"
            author="Nick Wilson"
            image={NFt2}
          />
        </div>
        
      </div>

      {/* right side section */}

      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <SupportedCountriesTable tableData={tableDataTopCreators} />
      </div>
    </div>
  );
};

export default Getstarted;
