import axiosInstance from "services/axios";

class APIService {
    static fetcher = (url: string) => axiosInstance.get(url).then((res) => res.data);

    static signup = (payload: any) => axiosInstance.post('/admins/auth/signup', payload).then((res) => res);

    static verifyOTP = (payload: any) => axiosInstance.post('/admins/auth/verify', payload).then((res) => res);

    static resendOTP = (payload: any) => axiosInstance.post('/admins/auth/resend-otp', payload).then((res) => res);

    static login = (payload: any) => axiosInstance.post('/admin/auth/login', payload).then((res) => res);

    static updateProfile = (id: string, body: any) => axiosInstance.put(`admins/${id}/update`, body).then((res) => res);

    static getMerchantData = (id: string) => axiosInstance.get(`/merchants/${id}`).then((res) => res);

    static getRequests = (id: string) => axiosInstance.get(`/merchants/${id}/requests/all`).then((res) => res);
    // static fetchRequests = (id) => axiosInstance.get(`${MERCHANTS_BASE_URL}/merchants/${id}/requests/all`).then((res) => res);

    static getOrders = (id: string) => axiosInstance.get(`/orders/merchants/${id}`).then((res) => res);

    static initiateFundWallet = (payload: any) => axiosInstance.post(`/wallet/fund/initiate`, payload, {}).then((res) => res);

    static confirmFundWallet = (payload: any) => axiosInstance.post(`/wallet/fund/confirm`, payload, {}).then((res) => res);

    static getTransactions = (id: string) => axiosInstance.get(`/`) 


}

export default APIService;

