import React from "react";
import { Toolbar } from "@mui/material";
import OrdersTable from "./table";

const Orders = () => {
  return (
    <div className="flex flex-col items-start justify-start p-2 ">
      <div className="flex flex-col items-start justify-start">
        <p className="shrink text-[15px] capitalize text-navy-700 dark:text-white">
          See your customer orders here
        </p>
      </div>
      <Toolbar />
      <OrdersTable />
    </div>
  );
};

export default Orders;
