import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface NotificationState {
  notifications: any[];
  unreadMessages: any[];
}

// Define the initial state using that type
const initialState: NotificationState = {
  notifications: [],
  unreadMessages: [],
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<any[]>) => {
      state.notifications = action.payload;
    },
    setUnreadMessages: (state, action: PayloadAction<any[]>) => {
      state.unreadMessages = action.payload;
    },
  },
});

export const {
  setNotifications,
  setUnreadMessages
} = notificationSlice.actions;

export default notificationSlice.reducer;
