import React from 'react'
import LiveTab from './tabs/live';
import TestTab from './tabs/test';

interface ItemI {
  title: string;
  component: any;
}

const ApiKeys = () => {
  const [currIndex, setCurrIndex] = React.useState<number>(0);

  const changeTab = (index: number) => {
    setCurrIndex(index);
  };

  const tabs: ItemI[] = [
    {
      title: "Test Credentials",
      component: <TestTab />,
    },
    {
      title: "Live Credentials",
      component: <LiveTab />
    }
  ];

  return (
    <div className="flex flex-col items-start justify-start p-2 ">
      <div className="flex flex-col items-start justify-start">
        {/* <p className="shrink text-[15px] capitalize text-navy-700 dark:text-white">
          Manage your settings and preferences
        </p> */}
      </div>
      <br />
      <div className="flex h-[70vh]  w-[100%] flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white">
        <ul
          className="mb-4 flex list-none flex-row flex-wrap border-b-0 ps-0"
          id="tabs-tab3"
          role="tablist"
          data-twe-nav-ref
        >
          {tabs.map((item: ItemI, index: number) => (
            <li role="presentation">
              <button
                className="border-transparent text-neutral-500 hover:border-transparent hover:bg-neutral-100 focus:border-transparent data-[twe-nav-active]:border-primary data-[twe-nav-active]:text-primary dark:hover:bg-neutral-700/60 dark:data-[twe-nav-active]:text-primary my-1 block border-x-0 border-b-2 border-t-0 px-7 pb-3.5 pt-4 text-xs font-medium uppercase leading-tight hover:isolate focus:isolate dark:text-white/50"
                id={`tabs-home-tab${index}`}
                data-twe-toggle="pill"
                data-twe-target="#tabs-home3"
                data-twe-nav-active
                role="tab"
                aria-controls="tabs-home3"
                aria-selected="true"
                onClick={() => changeTab(index)}
                style={{color: currIndex === index ? '#49A3FD' : 'gray'}}
              >
                {item?.title}
              </button>
            </li>
          ))}
        </ul>

        {/* Tab content */}
        <div className="w-[100%]" >
          <div
            className=" w-[100%] hidden opacity-100 transition-opacity duration-150 ease-linear data-[twe-tab-active]:block px-4"
            id="tabs-home3"
            role="tabpanel"
            data-twe-tab-active
            aria-labelledby="tabs-home-tab3"
          >
            {tabs[currIndex].component}
          </div>
        </div>
      </div>
    </div>
  );
};


export default ApiKeys