/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes";
import { useAppSelector } from "hooks/apphook";
import { RootState } from "store";
import React from "react";
import { getMerchantId } from "store/reducers/auth";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  const [shortenedId, setShortenedID] = React.useState("");
  const { merchantId, profile } = useAppSelector((state: RootState) => state.auth)
  // let merchantId = getMerchantId;

  React.useEffect(() => {
    if (merchantId) {
      let shortener = merchantId?.replaceAll("-", "").substring(8, 24);
      setShortenedID("***"+shortener+"***");
    }
  }, [merchantId])

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[25px] flex flex-col items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          {profile?.business_name}<span className="px-1 font-medium">&trade;</span>
        </div>
        <br/>
        <p>{shortenedId}</p>
      </div>
      <div className="mt-[32px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        <SidebarCard />
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
