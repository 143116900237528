// Admin Imports
import MainDashboard from "views/admin/default";

// Icon Imports
import {
  MdOutlineShoppingCart,
  MdBarChart,
  MdLock,
  MdSettings,
  MdOutlineSummarize,
  MdOutlineHome,
  MdRequestPage,
} from "react-icons/md";
import Customers from "views/admin/requests";
import Transactions from "views/admin/transactions";
import SettingsRoot from "views/admin/settings";
import Orders from "views/admin/orders";
import ApiKeys from "views/admin/api-keys";
import Getstarted from "views/admin/getstarted";

const routes = [
  {
    name: "Get started",
    layout: "/admin",
    path: "getstarted",
    icon: <MdOutlineHome className="h-6 w-6" />,
    component: <Getstarted />,
  },
  {
    name: "Overview",
    layout: "/admin",
    path: "default",
    icon: <MdOutlineSummarize className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  // {
  //   name: "Requests",
  //   layout: "/admin",
  //   path: "requests",
  //   icon: <MdRequestPage className="h-6 w-6" />,
  //   component: <Customers />,
  // },
  {
    name: "Orders",
    layout: "/admin",
    path: "orders",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Orders />,
  },
  {
    name: "Transactions",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <Transactions />,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <MdSettings className="h-6 w-6" />,
    component: <SettingsRoot />
  },
  {
    name: "API & Webhooks",
    layout: "/admin",
    path: "security",
    icon: <MdLock className="h-6 w-6" />,
    component: <ApiKeys />,
  },
];
export default routes;
