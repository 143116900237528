import Card from "components/card";
import { IconButton, Tooltip } from "@mui/material";
import { RiExternalLinkLine } from "react-icons/ri";

const NftCard = (props: {
  image: string;
  title: string;
  author: string;
  download?: string;
  extra?: string;
}) => {
  const { title, author, image, extra } = props;

  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
    >
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={image}
            className="mb-3 h-full w-full rounded-md 3xl:h-full 3xl:w-full"
            alt=""
          />
         
        </div>

        <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2">
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {" "}
              {title}{" "}
            </p>
            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
             {author}{" "}
            </p>
          </div>

          <div className="flex flex-row-reverse md:mt-2 lg:mt-0 text-navy-700 dark:text-white">
            <Tooltip title="View documentation" >
              <IconButton>
                <RiExternalLinkLine className="text-navy-700 dark:text-white" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default NftCard;
