import axios from "axios";

export class AxiosConfig {
  private baseURL: string = "http://localhost:8111";  // Set your baseURL
  private axiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL + "/api/v1", 
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("x-toks"),
      },
    });

    this.init();
  }

  init() {
    this.axiosInstance.interceptors.request.use(async (req: any) => {
      try {
        // const accessToken = localStorage.getItem("x-toks");
       
        return req;
      } catch (error) {
        return Promise.reject(error);
      }
    });

    this.axiosInstance.interceptors.response.use(
      (res: any) => res,
      async (err: any) => {
        const originalConfig = err.config;
        if (err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            console.info("expired");

            try {
              const refreshToken = localStorage.getItem("x-toks");
              // Implement token refresh logic if needed
              // const refreshResponse = await axiosInstance.post("/auth/token", { refreshToken });
              // if (refreshResponse?.data) {
              //   localStorage.setItem("x-toks", refreshResponse?.data.accessToken);
              //   originalConfig.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;
              // }
              return this.axiosInstance(originalConfig);
            } catch (_error: any) {
              if (_error.response && _error.response.data) {
                return Promise.reject(_error.response.data);
              }
              return Promise.reject(_error);
            }
          }
          if (err.response.status === 403 && err.response.data) {
            return Promise.reject(err.response.data);
          }
        }
        return Promise.reject(err);
      }
    );
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }
}

// Usage example
const axiosConfig = new AxiosConfig();
const axiosInstance = axiosConfig.getAxiosInstance();
export default axiosInstance;
