import Card from "components/card";

const Widget = (props: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  button1?: any;
  button2?: any;
}) => {
  const { icon, title, subtitle, button1, button2 } = props;
  return (
    <Card extra="!flex-row flex-grow items-center justify-between rounded-[20px]">
      <div className="flex flex-grow flex-row items-center">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>

        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
        </div>
      </div>
      <div className="flex flex-col justify-end item-end" >
        {button1}
        {button2}
      </div>
    </Card>
  );
};

export default Widget;
