import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import "react-phone-number-input/style.css";
import InputField from "components/fields/InputField";
import * as Yup from "yup";
import SelectField from "components/fields/SelectField";
import { MdRectangle } from "react-icons/md";
import Checkbox from "components/checkbox";

const claims = [
  {
    label: "Developer",
    value: "developer",
  },
  {
    label: "Customer Support",
    value: "support",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

const AddTeamMember = () => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email_address: Yup.string()
      .email("Email address is not valid")
      .required("Email address is required"),
    phone_number: Yup.string().required("Phone number is required"),
    claim: Yup.string().required("Claim is required"),
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    claim: "",
  };

  // const submit = (value: any) => {
  //   console.log("SUBMIT TRIGGERED HERE :::: ");
  // };

  return (
    <div className="flex w-[100%] flex-row text-navy-700 dark:text-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(v) => {
          console.log("EMAIL  ::: ", v.email_address);
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          values,
          isValid,
          getFieldProps,
        }) => (
          <Box
            component={Form}
            p={2}
            py={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={2}
            >
              <Typography fontSize={14} fontFamily={"cursive"} fontWeight={600}>
                Fill in the form below
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
                alignItems={"end"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Typography
                    fontSize={13}
                    fontFamily={"monospace"}
                    fontWeight={600}
                  >
                    {" "}
                    Analytics
                  </Typography>
                  <Typography px={1}>-</Typography>
                  <MdRectangle style={{ color: "navy" }} />
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  <Typography
                    fontSize={13}
                    fontFamily={"monospace"}
                    fontWeight={600}
                  >
                    {" "}
                    Wallet
                  </Typography>
                  <Typography px={1}>-</Typography>
                  <MdRectangle style={{ color: "teal" }} />
                </Box>
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <Field
                    type="text"
                    id="first_name"
                    name="first_name"
                    component={InputField}
                    {...getFieldProps("first_name")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.first_name && errors.first_name
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="first_name"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <Field
                    type="text"
                    id="last_name"
                    name="last_name"
                    component={InputField}
                    {...getFieldProps("last_name")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.last_name && errors.last_name
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter your last name"
                  />
                  <ErrorMessage
                    name="last_name"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                {/* Email Field */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <Field
                    type="email"
                    id="email_address"
                    name="email_address"
                    component={InputField}
                    {...getFieldProps("email_address")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.email_address && errors.email_address
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email_address"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone number
                  </label>
                  <Field
                    type="number"
                    id="phone_number"
                    name="phone_number"
                    component={InputField}
                    {...getFieldProps("phone_number")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.phone_number && errors.phone_number
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter phone number"
                  />
                  <ErrorMessage
                    name="phone_number"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="claim"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Claim
                  </label>
                  <Field
                    type="text"
                    id="claim"
                    name="claim"
                    component={SelectField}
                    {...getFieldProps("claim")}
                    items={claims}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.claim && errors.claim
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter registration number"
                  />
                  <ErrorMessage
                    name="claim"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <label
                    htmlFor="claim"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Actions
                  </label>
                  <Box
                    mt={1.5}
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"start"}
                    alignItems={"center"}
                  >
                    <div >
                      <Checkbox color="navy" extra="w-[36px] h-[36px]" />
                    </div>
                    <div style={{marginLeft: 6, marginRight: 6}}>
                      <Checkbox color="teal" extra="w-[36px] h-[36px]" />
                    </div>
                  </Box>
                </div>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}></Grid>
            <br />

            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{ textTransform: "capitalize", borderRadius: 12 }}
            >
              Continue
            </Button>
          </Box>
        )}
      </Formik>
    </div>
  );
};

export default AddTeamMember;
