import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/layout/fine_girl.jpg";
// import watermark from "assets/svg/bg_doudle_light.svg";
import { Outlet } from "react-router-dom";
// import routes from "routes";
// import ForgotPassword from "views/auth/ForgotPassword";
// import SignIn from "views/auth/SignIn";

interface PropI {
  child: React.ReactNode;
}

export default function Auth({ child }: PropI) {
  // const getRoutes = (routes: RoutesType[]): any => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/auth") {
  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div
        className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900"
        style={{ backgroundColor: "red" }}
      >
        <main className={`mx-auto min-h-full `}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                {child}
                {/* <Outlet /> */}
                {/* <Routes> */}
                {/* {getRoutes(routes)} */}
                {/* <Route
                    path="/auth"
                    element={<Navigate to={"/auth/sign-in"} replace/>}
                  /> */}
                {/* <Route
                    path="/auth/sign-in"
                    element={<SignIn />}
                  />
                  <Route
                    path="/auth/forgot-password"
                    element={<ForgotPassword />}
                  /> */}
                {/* </Routes> */}
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-center justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${authImg})` }}
                  >
                    <div>
                      <h1 className="text-white ">Afrikunet</h1>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
