import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface TransactionState {
  transactions: any[];
}

// Define the initial state using that type
const initialState: TransactionState = {
  transactions: [],
};

export const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTransactions: (state, action: PayloadAction<any>) => {
      state.transactions = action.payload;
    },
  },
});

export const { setTransactions } = transactionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const getJobs = (state: RootState) => state.;

export default transactionSlice.reducer;
