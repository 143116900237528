// Custom components
import React from "react";

function SelectField(props: {
  id: string;
  items: any[];
  label: string;
  value?: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  field?: any; // This prop comes from Formik
  errors?: any; // This also comes from Formik
  touched?: any; // This also comes from Formik
  helperText?: string; // This also comes from Formik
}) {
  const {
    id,
    extra,
    type,
    placeholder,
    state,
    disabled,
    value,
    field,
    items,
  } = props;


  return (
    <div className={`${extra}`}>
      <select
        {...field}
        disabled={disabled}
        type={type}
        value={value}
        id={id}
        placeholder={placeholder}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      >
        <option disabled value="">Select an option</option>
        {(items ?? [])?.map((option) => (
          <option key={option.value ?? option} value={option.value ?? option}>
            {option?.label ?? option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectField;
