import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "..";

// Define a type for the slice state
interface SettingsState {
  theme: string;
  preference: any;
  profile: any;
  countries: any;
}

// Define the initial state using that type
const initialState: SettingsState = {
  theme: "light",
  profile: null,
  preference: null,
  countries: []
};

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    setCountries: (state, action: PayloadAction<any>) => {
      state.countries = action.payload;
    }
  },
});

export const { setThemeMode } = settingSlice.actions;

export default settingSlice.reducer;
