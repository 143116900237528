import React from "react";
import "../style.css";
import Switch from "components/switch";
import { useAppDispatch, useAppSelector } from "hooks/apphook";
import { setThemeMode } from "store/reducers/settings";
import { RootState } from "store";

const languagesSupported = [
  { label: "English", value: "en", flag: "🇬🇧" },
  { label: "French", value: "fr", flag: "🇫🇷" },
  { label: "Spanish", value: "es", flag: "🇪🇸" },
  { label: "Arabic", value: "ar", flag: "🇦🇪" },
];

const Preference = () => {
  const [walletOn, setWalletOn] = React.useState("Off");
  const [hideEarnings, setHideEarnings] = React.useState("Off");
  const [darkmode, setDarkmode] = React.useState(false);
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state: RootState) => state.auth);

  const toggleWallet = () => {
    alert("JUST TOGGLED WALLET");
    if (walletOn === "on") {
      setWalletOn("off");
    } else {
      setWalletOn("on");
    }
  };

  const toggleEarnings = () => {
    alert("JUST TOGGLED WALLET");
    if (hideEarnings === "on") {
      setHideEarnings("off");
    } else {
      setHideEarnings("on");
    }
  };

  return (
    <div className="item-center flex w-[100%] flex-col justify-start p-2">
      {/* Theme row */}
      <div className="grid w-[100%] grid-cols-1 gap-4 py-5 md:grid-cols-3">
        <div className="item-center flex flex-row justify-start">
          <p className="pr-6">Dark Mode</p>
          <div className="relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
            <Switch
              onToggle={() => {
                if (darkmode) {
                  document.body.classList.remove("dark");
                  setDarkmode(false);
                  dispatch(setThemeMode("dark"));
                } else {
                  document.body.classList.add("dark");
                  dispatch(setThemeMode("light"));
                  setDarkmode(true);
                }
              }}
            />
          </div>
        </div>

        {profile?.claim === "owner" ? (
          <>
            <div className="item-center flex flex-row justify-start">
              <p className="pr-6">Wallet</p>
              <div className="relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
                <Switch onToggle={toggleWallet} value={walletOn} />
              </div>
            </div>

            <div className="item-center flex flex-row justify-start">
              <p className="pr-6">Earnings</p>
              <div className="relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
                <Switch onToggle={toggleEarnings} value={hideEarnings} />
              </div>
            </div>
          </>
        ) : (
          <div className="item-center flex flex-row justify-start">
            <p className="pr-6">Lang</p>
            <div className="relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
              <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
                {languagesSupported.map((item, index: number) => (
                  <option value={item.value} key={index}>
                    <p>
                      {item.flag} - {item.label}
                    </p>
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>

      <div className="flex w-[100%] flex-row justify-center py-2">
        <div className="h-[0.5px] w-[100%] bg-[grey]" />
      </div>

      {/* Theme row */}
      {profile?.claim === "owner" && (
        <div className="grid w-[100%] grid-cols-1 gap-4 py-5 md:grid-cols-3">
          <div className="item-center flex flex-row justify-start">
            <p className="pr-6">Push Notification</p>
            <div className="relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
              <Switch
                onToggle={() => {
                  if (darkmode) {
                    document.body.classList.remove("dark");
                    setDarkmode(false);
                    dispatch(setThemeMode("dark"));
                  } else {
                    document.body.classList.add("dark");
                    dispatch(setThemeMode("light"));
                    setDarkmode(true);
                  }
                }}
              />
            </div>
          </div>

          <div className="item-center flex flex-row justify-start">
            <p className="pr-6">Email Notification</p>
            <div className="relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
              <Switch onToggle={toggleWallet} value={walletOn} />
            </div>
          </div>

          <div className="item-center flex flex-row justify-start">
            <p className="pr-6">Lang</p>
            <div className="relative mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
              <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
                {languagesSupported.map((item, index: number) => (
                  <option value={item.value} key={index}>
                    <p>
                      {item.flag} - {item.label}
                    </p>
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Preference;
