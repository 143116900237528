import React from "react";
import { useLocation } from "react-router-dom";
import Settings from "./settings";
import Team from "./pages/business/businessprofile";

const SettingsRoot = () => {

  const location = useLocation();
  const [current, setCurrent] = React.useState(<Settings />)

  console.log("current location ::: ", location.pathname);
  

  React.useEffect(() => {
    if (location.pathname.includes("/business/team")) {
     setCurrent(<Team />)
    }
  
  }, [location.pathname])


  return current;

  // return (
    // <div>

    //   <Routes>
    //     <Route path="/" element={<Settings />} />
    //     <Route path="/admin/settings/business/team" element={<Team />} />
    //     {/* <Route
    //     path="/admin/settings/business/preference"
    //     element={<Preference />}
    //   />
    //   <Route path="/admin/settings/business/profile" element={<Profile />} />

    //   <Route path="/admin/settings/security/2fa" element={<Security />} />
    //   <Route path="/admin/settings/security/reset-password" element={<Security />} /> */}
    //   </Routes>
    //   <Outlet />
    // </div>
  // );
};

export default SettingsRoot;
