import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";

import App from "./App";
import { store } from "store";
import { SWRConfig } from "swr";
import APIService from "services/api";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <SWRConfig
      value={{
        // refreshInterval: 3000,
        fetcher: (url) => APIService.fetcher(url),
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SWRConfig>
  </Provider>
);
