import useSWR from 'swr';

export default function useRequests(id: string) {
    const { data, mutate, error } = useSWR(`/merchants/${id}/requests/all`);
    console.log('REQUESTS ALL ::: ', data);
    const loading = !data && !error;
    const loggedOut =
        (error && error?.message === 'No token provided.') ||
        error?.response?.status === 401 ||
        error?.response?.status === 403 ||
        error?.response?.data?.message === 'No user found!';

    return {
        loading,
        loggedOut,
        data,
        mutate
    };
}
