// Custom components
import React from "react";

function InputField(props: {
  id: string;
  label: string;
  value?: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  field?: any; // This prop comes from Formik
  errors?: any; // This also comes from Formik
  touched?: any; // This also comes from Formik
  helperText?: string; // This also comes from Formik
}) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    value,
    errors,
    helperText,
    touched,
    field,
  } = props;

  let hasError;
  if (touched && errors && field) {
    hasError = touched[field.name] && errors[field.name];
  }

  return (
    <div className={`${extra}`}>
      <input
        {...field}
        disabled={disabled}
        type={type}
        value={value}
        id={id}
        
        placeholder={placeholder}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      />
      {/* {hasError && (
        <span className="text-xs text-[red]">{helperText ?? ""}</span>
      )} */}
    </div>
  );
}

export default InputField;
