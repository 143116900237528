import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

// const columns: GridColDef<(typeof rows)[number]>[] = [
// //   { field: 'id', headerName: 'ID', width: 90 },
//   {
//     field: 'firstName',
//     headerName: 'First name',
//     width: 150,
//     editable: true,
//     flex: 1,
//   },
//   {
//     field: 'lastName',
//     headerName: 'Last name',
//     width: 150,
//     editable: true,
//     flex: 1,
//   },
//   {
//     field: 'email_address',
//     headerName: 'Email Address',
//     type: 'string',
//     width: 110,
//     editable: true,
//     flex: 1,
//   },
//   {
//     field: 'phone',
//     headerName: 'Phone Number',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     flex: 1,
//     valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
//   },
//   {
//     field: 'id',
//     headerName: '',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     flex: 2,
//     renderCell: (value) => {
//         return <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} alignItems={'center'} height={'100%'} >
//             <Button variant='contained' size='small' sx={{textTransform: 'capitalize', fontSize: 12, borderRadius: 16}} >
//                 <Typography>Report</Typography>
//             </Button>
//             <Button variant='outlined' size='small' sx={{mx: 1.5, textTransform: 'capitalize', fontSize: 12, borderRadius: 16}} >
//                 <Typography>Block</Typography>
//             </Button>
//         </Box>
//     },
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

export default function TransactionTable() {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={[]}
        columns={[]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
        
          },
        }}
        
        className='text-navy-700 dark:text-white'
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
