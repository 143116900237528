import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../";

// Define a type for the slice state
interface AuthState {
  isAuth: boolean;
  profile: any;
  merchantId: string;
}

// Define the initial state using that type
const initialState: AuthState = {
  isAuth: false,
  profile: {
    fname: "Jon",
    lName: 'Doe',
    claim: 'owner',
    email: 'abu@gmail.com',
    business_email: 'afrikunet@gmail.com',
    business_name: "Afrikunet"
  },
  merchantId: "0f9c1f84-6a58-4539-ae06-cd28b3d8e446",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setProfile: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
  },
});

export const { setAuth, setProfile } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getAuth = (state: RootState) => state.auth.isAuth;
export const getProfile = (state: RootState) => state.auth.profile;
export const getMerchantId = (state: RootState) => state.auth.merchantId;

export default authSlice.reducer;
