import nft1 from "assets/svg/bg_doudle_light.svg";
import appPreview from "assets/img/app_preview.png";

const Banner1 = () => {
  return (
    //
    <div style={{ backgroundImage: `url(${nft1})`, borderRadius: 16 }}>
      <div
        className="grid w-[100%] grid-cols-1 gap-2 md:grid-cols-2"
        style={{ backgroundColor: "#0159b1e0",  borderRadius: 16 }}
      >
        <div className="flex w-full flex-col rounded-md bg-cover px-[10px] py-[30px] md:px-[24px] md:py-[16px]">
          <div className="w-full">
            <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[90%] md:text-3xl md:leading-[42px] lg:w-[86%] xl:w-[95%] 2xl:w-[82%] 3xl:w-[90%]">
              Share, Gift and Redeem Vouchers With Ease
            </h4>
            <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[70%]">
              Use for Music, Games, Apps, Movie Tickets, Books and Lots more
            </p>

            <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
              <button className="text-black linear rounded-md bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70">
                View website
              </button>
              <button className="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2">
                Watch Video
              </button>
            </div>
          </div>
        </div>
        <div
            style={{
              backgroundImage: `url(${appPreview})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPositionX: "right",
              marginTop: 18
            }}
          />
      </div>
    </div>
  );
};

export default Banner1;
