type RowObj = {
	flag: string;
	name: string; 
  code: string;
	rating: number; 
};

const tableColumnsTopCreators: RowObj[] = [
	{
    flag: "🇳🇬",
    name : "Nigeria",
    code: "NGN", 
    rating:97
  },
  {
    flag: "🇬🇭",
    name : "Ghana",
    code: "GHS", 
    rating:87
  },
  {
    flag: "🇰🇪",
    name : "Kenya",
    code: "KHS", 
    rating:82
  },
  {
    flag: "🇿🇦",
    name : "South Africa",
    code: "ZAR", 
    rating:68
  },
  {
    flag: "🇺🇬",
    name : "Uganda",
    code: "UGX", 
    rating:68
  },
  {
    flag: "🇪🇬",
    name : "Egypt",
    code: "EGY", 
    rating:46
  },
  {
    flag: "🇸🇱",
    name : "Sierra Leone",
    code: "SLL", 
    rating:38
  },
  {
    flag: "🇹🇿",
    name : "Tanzania",
    code: "TZS", 
    rating:58
  },
  {
    flag: "🇹🇷",
    name : "Rwanda",
    code: "RWF", 
    rating:59
  },
  {
    flag: '🇲🇼',
    name : "Malawi",
    code: "MWK", 
    rating:55
  },
  {
    flag: '🇲🇪',
    name : "Ethiopia",
    code: "ETB", 
    rating:71
  },
  {
    flag: '🇺🇸',
    name : "United States",
    code: "USD", 
    rating:71
  }
];

export default tableColumnsTopCreators;
