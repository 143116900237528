import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { IconButton } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";

interface DialogProps {
  open: boolean;
  maxWidth?: string;
  setOpen: any;
  content: any;
  title?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog({
  open,
  title,
  content,
  setOpen,
  maxWidth,
}: DialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      disableEscapeKeyDown
      maxWidth={"xl"}
      // onClose={handleClose}
      className="rounded-md "
      aria-describedby="alert-dialog-slide-description"
      sx={{
        backdropFilter: "blur(5px) sepia(5%)",
      }}
      // 👇 Props passed to Paper (modal content)
      PaperProps={{ sx: { borderRadius: "21px" } }}
    >
      <div className="flex flex-row items-center justify-between bg-white/30 backdrop-blur-lg shadow-lg text-navy-700 dark:!bg-navy-900 dark:text-white">
        <h4 className="pl-2 bg-transparent text-navy-700 dark:!bg-navy-900 dark:text-white" > {title}</h4>
        <IconButton onClick={() => setOpen(false)}>
          <IoCloseCircleOutline className="bg-transparent text-navy-700 dark:!bg-navy-900 dark:text-white" />
        </IconButton>
      </div>
      <div className="p-2 flex flex-row items-center justify-between bg-lightPrimary text-navy-700 dark:!bg-navy-900 dark:text-white">
        {content}
      </div>
    </Dialog>
  );
}
