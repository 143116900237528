import { Button, IconButton, TextField } from "@mui/material";
import React from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FiCopy } from "react-icons/fi";

const TestTab = () => {
    const [showPub, setShowPub] = React.useState(false);
    const [showSec, setShowSec] = React.useState(false);

  return (
    <div className="item-center flex flex-col justify-start p-4">
      <div className="item-center flex flex-row justify-between">
        <p className="text-[21px] font-bold capitalize text-navy-700 dark:text-white">
          Test Keys & Credentials
        </p>
        <div>
          <button className="text-black linear rounded-md bg-gradient-to-b from-white/50 to-white/10 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70 ">
            Reset
          </button>
        </div>
      </div>
      <div className="my-8 h-[0.5px] w-[100%] bg-[white]" />
      <div className="item-center flex flex-row justify-between">
        <p className="text-[18px] font-[500] capitalize text-navy-700 dark:text-white">
          Public Key
        </p>
        <div className="item-center flex flex-row justify-end bg-[#eee] dark:bg-[transparent] px-[2]">
          <input
            className="w-[200px] px-2 text-[14px] font-[400] text-navy-700 dark:text-white"
            disabled
            value={showPub ? "8493hdh94hf94hd893h" : "text_key_****************"}
          />
          <Button
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            startIcon={showPub ? <BsEye /> : <BsEyeSlash />}
            onClick={() => {
                setShowPub(!showPub);
            }}
          >
            {
                showPub ? "Hide" : "Show"
            }
          </Button>
          <Button
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            startIcon={<FiCopy />}
          >
            Copy
          </Button>
        </div>
      </div>
      <br />
      <br />
      <div className="item-center flex flex-row justify-between">
        <p className="text-[18px] font-[500] capitalize text-navy-700 dark:text-white">
          Secret Key
        </p>
        <div className="item-center flex flex-row justify-end bg-[#eee] dark:bg-[transparent] px-[2]">
          <input
            className="w-[200px] px-2 text-[14px] font-[400] text-navy-700 dark:text-white"
            disabled
            value={showSec ? "8493hdh94hf94hd893h" : "text-sec-****************"}
          />
          <Button
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            startIcon={showSec ? <BsEye /> : <BsEyeSlash />}
            onClick={() => {
                setShowSec(!showSec);
            }}
          >
            {
                showSec ? "Hide" : "Show"
            }
          </Button>
          <Button
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            startIcon={<FiCopy />}
          >
            Copy
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TestTab;
