import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
// import Team from "views/admin/settings/pages/business/team";
// import SettingsRoot from "views/admin/settings";
import APIService from "services/api";
import { useAppDispatch } from "hooks/apphook";
import { setProfile } from "store/reducers/auth";
import React from "react";
import useRequests from "hooks/useRequests";
import { setRequests } from "store/reducers/requests";
import SignIn from "views/auth/SignIn";
import ForgotPassword from "views/auth/ForgotPassword";
import useOrders from "hooks/useOrders";

const App = () => {
  let merchantId = "d5f05cce-646f-4bb5-ae69-ca10d5b5bb73";
  const dispatch = useAppDispatch();
  const { data: requestsData } = useRequests(merchantId);
  const { data: ordersData} = useOrders(merchantId);

  const init = React.useCallback(async () => {
    try {
      const response = await APIService.getMerchantData(merchantId);
      console.log("MERCHANT INFO ::: ", response.data);

      if (response.status === 200) {
        dispatch(setProfile(response.data));
      }

      const requestResp = await APIService.getRequests(merchantId);
      console.log("REQUEST INFO ::: ", requestResp.data);

      if (response.status === 200) {
        dispatch(setProfile(response.data));
      }
    } catch (error) {}
  }, [dispatch, merchantId]);

  React.useEffect(() => {
    init();
  }, [init]);

  React.useEffect(() => {
    if (requestsData) {
      dispatch(setRequests(requestsData));
    }
  }, [dispatch, requestsData]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/sign-in" replace />} />
      {/* <Route path="auth" element={<AuthLayout />}> */}
      <Route path="/sign-in" element={<SignIn />} />
      {/* <Route path="/auth/sign-in" element={<SignIn />} /> */}
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {/* </Route> */}
      <Route path="admin/*" element={<AdminLayout />} />
      {/* <Route path="/admin/settings/business/team" element={<SettingsRoot />} /> */}
      {/* <Route
        path="/admin/settings/business/preference"
        element={<Preference />}
      /> */}
      {/* <Route path="/admin/settings/business/profile" element={<Profile />} />

      <Route path="/admin/settings/security/2fa" element={<Security />} />
      <Route path="/admin/settings/security/reset-password" element={<Security />} /> */}
    </Routes>
  );
};

export default App;
