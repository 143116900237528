import React from "react";
import { Avatar, Box, Button, Grid, Toolbar } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import "react-phone-number-input/style.css";
import InputField from "components/fields/InputField";
import * as Yup from "yup";

const Profile = () => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email_address: Yup.string()
      .email("Email address is not valid")
      .required("Email address is required"),
    phone_number: Yup.string().required("Phone number is required"),
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
  };

  // const submit = (value: any) => {
  //   console.log("SUBMIT TRIGGERED HERE :::: ");
  // };

  return (
    <div className="flex w-[100%] flex-row text-navy-700 dark:text-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(v) => {
          console.log("EMAIL  ::: ", v.email_address);
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          values,
          isValid,
          getFieldProps,
        }) => (
          <Box
            component={Form}
            p={2}
            py={2}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box pb={5}>
              <Avatar sx={{ width: 100, height: 100 }} />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <Field
                    type="text"
                    id="first_name"
                    name="first_name"
                    component={InputField}
                    {...getFieldProps("first_name")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.first_name && errors.first_name
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="first_name"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <Field
                    type="text"
                    id="last_name"
                    name="last_name"
                    component={InputField}
                    {...getFieldProps("last_name")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.last_name && errors.last_name
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter your last name"
                  />
                  <ErrorMessage
                    name="last_name"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {/* Email Field */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <Field
                    type="email"
                    id="email_address"
                    name="email_address"
                    component={InputField}
                    {...getFieldProps("email_address")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.email_address && errors.email_address
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email_address"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div>
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone number
                  </label>
                  <Field
                    type="number"
                    id="phone_number"
                    name="phone_number"
                    component={InputField}
                    {...getFieldProps("phone_number")}
                    className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm focus:outline-none ${
                      touched.phone_number && errors.phone_number
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-indigo-500"
                    }`}
                    placeholder="Enter phone number"
                  />
                  <ErrorMessage
                    name="phone_number"
                    component="span"
                    className="text-xs text-red-500"
                  />
                </div>
              </Grid>
            </Grid>
            <Toolbar />

            <Button
              variant="contained"
              fullWidth
              type="submit"
              sx={{ textTransform: "capitalize", borderRadius: 12 }}
            >
              Continue
            </Button>
          </Box>
        )}
      </Formik>
    </div>
  );
};

export default Profile;
