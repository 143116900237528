import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./reducers/loader";
import authReducer from "./reducers/auth";
import requestsReducer from "./reducers/requests";
import ordersReducer from "./reducers/orders"
import transactionsReducer from "./reducers/transactions";
import settingsReducer from "./reducers/settings"
import notificationsReducer from "./reducers/notifications"

export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    auth: authReducer,
    settings: settingsReducer,
    orders: ordersReducer,
    requests: requestsReducer,
    transactions: transactionsReducer,
    notifications: notificationsReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
