import React from "react";
import { Toolbar } from "@mui/material";
import TransactionTable from "./table";

const Transactions = () => {
  return (
    <div className="flex flex-col items-start justify-start p-2 ">
      <div className="flex flex-col items-start justify-start">
        <p className="shrink text-[15px] capitalize text-navy-700 dark:text-white">
          See a record of all transactions here
        </p>
      </div>
      <Toolbar />
      <TransactionTable />
    </div>
  );
};

export default Transactions;
